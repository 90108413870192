/*
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 13:43:36
 */
import request from '@/utils/request'

export function fetchNewcomerItems(data) {
  return request({
    url: '/newcomer/items',
    params: data
  })
}
