<!--
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 17:25:38
-->
<template>
  <div class="container">
    <img src="https://oss.meijinguanjia.com/mini/newcomer/top-bg.png" mode="widthFix" class="img">
    <div class="box ">
      <template v-for="item in list" :key="item.id">
        <div class="content row" @click="goToItem(item.path)">
          <img class="left" :src="item.url + '!small'">
          <div class="right col pl-30 flex1">
            <text class="fs-2 c-3 b">{{ item.title }}</text>
            <text class="fs-6 c-9  flex1  mt-10">{{ item.desc }}</text>
            <div class="bot row justify-between align-center">
              <div class="row align-base">
                <span class="fs-2 din price">¥{{ item.price }}</span>
                <text class="fs-5 c-9 lt ml-10">{{ item.originalPrice }}</text>
              </div>
              <div class="cbtn mini" style="display:flex;align-items:center;justify-content:center">
                <span v-if="isMini" style="color:#fff">立即购买</span>
                <wx-open-launch-weapp v-else id="launch-btn" username="gh_5bac9e9f9a1a" :path="item.path">
                  <div v-is="'script'" type="text/wxtag-template">

                    <span style="color:#fff;font-size:12px;line-height: 1;">立即购买</span>
                  </div>
                </wx-open-launch-weapp>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="rules col mt-30">
        <text class="title b c-3 fs-4">规则说明</text>
        <text class="rule c-9 fs-6">1. 新用户拥有一次福利价下单机会，下单后将无法再继续享受福利价。</text>
        <text class="rule c-9 fs-6">2. 同一地址、同一手机和微信视为同一用户。</text>
        <text class="rule c-9 fs-6">3. 本活动最终解释权为深圳美今家居生活服务有限公司。</text>
      </div>
    </div>
    <img src="https://oss.meijinguanjia.com/mini/newcomer/bottom-bg.png" mode="widthFix" class="img">
  </div>
</template>
<script setup>
import qs from 'qs'
import { fetchNewcomerItems } from '@/API/newcomer'
import { onMounted, ref } from 'vue'
import { authTag } from '../../utils/wechat'
import { useRoute } from 'vue-router'
const list = ref([])
const isMini = ref(true)
const route = useRoute()
onMounted(async () => {
  if (navigator.userAgent.indexOf('mini') > -1) {
    isMini.value = true
  } else {
    isMini.value = false
    authTag()
  }
  const query = route.query
  query.sid = query.sid || 12
  const result = await fetchNewcomerItems({ typeId: query.sid })
  list.value = result.filter(v => (query?.rid ? true : v.title.indexOf('皮沙发') === -1)).map(v => {
    query.id = v.id
    v.path = `/pages/item/item?${qs.stringify(query)}`
    return v
  })
  console.log(result)
})

function goToItem(path) {
  wx.miniProgram.navigateTo({ url: path })
}
</script>

<style lang="scss"  scoped>
.price {
  font-family: DIN;
  // color: $color;
  color: var(--color);
  font-weight: bold;
}

img {
  object-fit: cover;
}

.container {
  min-height: 100vh;
  background-image: url("https://oss.meijinguanjia.com/mini/newcomer/bottom-bg.png!mobile");
  background-position: bottom;
  background-size: 750px 100000px;
}

.img {
  display: block;
  width: 100%;
}

.box {
  width: 750px;
  padding: 30px 60px;
  box-sizing: border-box;
  background: url("https://oss.meijinguanjia.com/mini/newcomer/bbg.png");
  background-size: 746px 100%;
}

.content {
  background: fff;
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 12px;
  box-sizing: border-box;
  /* background:linear-gradient(to bottom,#fff3ed ,#fad0c2) ; */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 -8px 10px rgba(200, 200, 200, 0.15);
}

.left {
  width: 250px;
  height: 200px;
  border-radius: 6px;
}

.tip {
  font-size: 20px;
  border: 1px solid #fcdedf;
  color: #ff2629;
  background: #fff6f6;
  padding: 2px 4px;
  border-radius: 6px;
}

.bot {

  width: 100%;

}

.cbtn {
  font-size: 24px;
  background-color: var(--color);
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  padding: 4px 20px;
}

.rules text {
  line-height: 2;
}

.title {
  display: block;
  text-align: center;
}
</style>
